import _isEmpty from 'lodash/isEmpty.js';

export default defineNuxtRouteMiddleware((_to, _from) => {
  const { loggedIn } = storeToRefs(useAuthStore());
  const { hasActivePlan, userData } = storeToRefs(useUserStore());

  if (!loggedIn.value) {
    return navigateTo('/login');
  }

  if (userData.value.isSuperuser || userData.value.isSalesperson) {
    return;
  }

  if (_isEmpty(userData.value.plan) && userData.value.type === 'shipper') {
    return;
  }

  if (!hasActivePlan.value) {
    return navigateTo('/plan-error');
  }
});
